import { getYear } from 'date-fns';
import React from 'react';

import './style.css';

const Footer = () => {
  const year = getYear(Date.now());

  return (
    <footer>
      <div className="content">
        <div>
          Euer eigener Shop mit euren Stars. Nach dem Sammelfieber ist euer
          Stardasein nicht beendet. Wir verewigen euch auf vielen tollen
          Produkten.
        </div>
        <div>
          <h3>Allgemeines</h3>
          <ul>
            <li><a href="/AGB_Online_Shop_Stickerstars.pdf">AGB</a></li>
            <li><a href="/Widerrufsbelehrung_Stickerstars_Online_Shop.pdf">Widerrufsbelehrung</a></li>
            <li><a href="/Muster_Widerrufsformular.pdf">Muster Widerrufsformular</a></li>
          </ul>
        </div>
      </div>
      <div className="legal-wrapper">
        <section className="legal">
          <div className="copy">© {year} Stickerstars GmbH</div>
          <div>
            <a href="https://www.stickerstars.de/impressum" target="_BLANK" rel="noopener noreferrer">Impressum</a>
            <a href="https://www.stickerstars.de/datenschutz" target="_BLANK" rel="noopener noreferrer">Datenschutz</a>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
