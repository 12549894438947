import styled from '@emotion/styled';

export const Box = styled.div`
  background: #f2f2fd;
  padding: 2rem;
  color: black;
  margin: 3rem 0 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.25fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

export const Line = styled.div`
  margin-top: 3rem;
  border: 1px solid #e9e9e9;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 40%; */

  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-top: 24px;
  }
`;

export const Image = styled.img`
  display: block;
  mix-blend-mode: multiply;
  /* position: absolute; */
  width: 100%;
  /* top: -18px;
    left: 0; */

  @media screen and (max-width: 900px) {
    /* position: unset; */
    /* width: 50%; */
  }

  @media screen and (max-width: 768px) {
    /* position: unset;
        width: 60%; */
  }
`;

export const Headline = styled.h2`
  font-weight: 900;
  font-size: 1.75rem;
  line-height: 120%;
  margin: 0;
`;

export const Subline = styled.p`
  font-weight: 300;
  font-size: 1rem;
  line-height: 150%;
  margin: 0.5rem 0 1.5rem;
`;

export const Link = styled.a`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #4144f3;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
