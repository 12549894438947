import React from 'react';

import { Box, Headline, Image, Line, Link, Subline, Text } from './styles';
import samples from '../../images/samples.png';

export default function CrossSelling() {
  return (
    <>
      <Line />
      <Box>
        <Image src={samples} />
        <Text>
          <Headline>
            Stärke dein soziales Umfeld mit einem individuell gestalteten
            Stickeralbum
          </Headline>
          <Subline>
            Das einzigartige Sammelerlebnis für jede Gesellschaft – schon
            ausprobiert?
          </Subline>
          <Link
            href="https://designer.stickerstars.de/album/new/?entrypoint=business"
            target="_blank"
          >
            Lieblingsvorlage wählen und designen →
          </Link>
        </Text>
      </Box>
    </>
  );
}
