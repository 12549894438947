import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { breakpoints } from '../../utils/styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.45rem;
  margin: 0 auto;
  max-width: 1100px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 1.45rem;
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  background: white;
  padding: 0 1.25rem;
  display: flex;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin: 0 auto;
  max-width: 80rem;
  width: 100%;
`;

export const LogoImage = styled.img`
  text-decoration: none;
  position: absolute;
  top: -10px;
  width: 68px;

  @media (min-width: ${breakpoints.l}px) {
    width: 110px;
    top: -10px;
  }
`;

export const LogoImageStartPage = styled.img`
  text-decoration: none;
  position: absolute;
  top: -10px;
  right: 24px;
  width: 68px;

  @media (min-width: ${breakpoints.l}px) {
    width: 128px;
    right: 60px;
  }
`;

export const BasketImage = styled.img`
  text-decoration: none;
  width: 40px;
`;

export const EdekaImage = styled.img`
  text-decoration: none;
  display: block;
  width: 200px;
`;

export const GoBackButton = styled(Link)`
  font-size: 1rem;
  font-weight: normal;
  margin-left: 70px;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;

  @media (min-width: ${breakpoints.l}px) {
    margin-left: 125px;
  }
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
  }
`;

export const CartLink = styled(Link)`
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  position: relative;
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
  }
`;

export const CartCounter = styled.span`
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 0.8rem;
  float: right;
  z-index: 20;
  position: absolute;
  right: -15px;
  top: -15px;
`;
