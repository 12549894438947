import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import SEO from '../components/seo';

import ClubSelect from '../components/ClubSelect';
import { GlobalStyle } from '../utils/styles';
import Navigation from '../components/Navigation';
import StoreContext from '../context/StoreContext';
import Alert from '../components/Alert';
import Footer from '../components/Footer';
import CrossSelling from '../components/CrossSelling';

const Wrapper = styled.div`
  margin: 9rem auto 0;
  max-width: 1100px;
  padding: 0px 1.25rem 1.45rem;
`;

const PageLayout = ({ children }) => {
  const {
    store: { clubId, clubName, theme },
    resetClub,
  } = useContext(StoreContext);

  const handleClubReset = () => {
    resetClub();
  };

  return (
    <>
      <SEO />
      <GlobalStyle />
      {clubId ? (
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <Navigation siteTitle={data.site.siteMetadata.title} />
              <Wrapper>
                <Alert type={theme === 'normal' ? 'alt' : theme}>
                  Du kaufst für <b>{clubName}</b> ein.{' '}
                  <a
                    href="/"
                    data-testid="qa-reset-club"
                    onClick={handleClubReset}
                  >
                    Für ein anderes Projekt einkaufen?
                  </a>
                </Alert>
                {children}
                <CrossSelling />
              </Wrapper>
              <Footer />
            </>
          )}
        />
      ) : <ClubSelect />}
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
