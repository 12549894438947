import React from 'react';

import { Box } from './styles';

const styleMap = {
  alert: {
    color: '#E0004D',
    fontSize: '0.9rem',
  },
  info: {
    color: 'black',
  },
  alt: {
    background: 'rgb(22, 31, 78)',
    color: 'white',
    boxShadow: '0',
  },
  edeka: {
    background: '#1f3d7e',
    color: 'white',
    boxShadow: '0',
  },
};

const Alert = ({ type = 'info', children }) => {
  return <Box style={{ ...styleMap[type] }}>{children}</Box>;
};

export default Alert;
