import React from 'react';
import PropTypes from 'prop-types';

import { GlobalStyle } from '../utils/styles';
import ContextProvider from '~/provider/ContextProvider';

const Empty = ({ children }) => {
  return (
    <ContextProvider>
      <GlobalStyle />
      {children}
    </ContextProvider>
  );
};

Empty.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Empty;
