/**
 * ========================================================================
 * A module with general product / shop configuration. Most of this
 * should probably live on the server, we might want to figure out a way
 * to to achieve this via Shopify `metafields`.
 * ========================================================================
 */

/**
 * These are products that are actually regular (single-)stickers -
 * including the local club project sticker product as well as all pro
 * shop sticker products (including glitter).
 */

export const stickerProductIds = [
  'Shopify__Product__gid://shopify/Product/4401958715531',
  'Shopify__Product__gid://shopify/Product/4730073251979',
  'Shopify__Product__gid://shopify/Product/4730164183179',
  'Shopify__Product__gid://shopify/Product/4767573278859',
  'Shopify__Product__gid://shopify/Product/4764320628875',
  'Shopify__Product__gid://shopify/Product/4793047908491',
  'Shopify__Product__gid://shopify/Product/4793056854155',
  'Shopify__Product__gid://shopify/Product/6201256673448',
  'Shopify__Product__gid://shopify/Product/6201266208936',
  'Shopify__Product__gid://shopify/Product/6725971214504',
  'Shopify__Product__gid://shopify/Product/6725968461992',
  'Shopify__Product__gid://shopify/Product/7330471968984',
  'Shopify__Product__gid://shopify/Product/7330472034520',
  'Shopify__Product__gid://shopify/Product/7529058402520',
  'Shopify__Product__gid://shopify/Product/7529056633048',
  'Shopify__Product__gid://shopify/Product/7545027068120',
  'Shopify__Product__gid://shopify/Product/7545028804824',
  'Shopify__Product__gid://shopify/Product/7591564902616',
  'Shopify__Product__gid://shopify/Product/7591565426904',
  'Shopify__Product__gid://shopify/Product/7646962647256',
  'Shopify__Product__gid://shopify/Product/7646962778328',
  'Shopify__Product__gid://shopify/Product/8038593888524', 
  'Shopify__Product__gid://shopify/Product/8038594674956',
  'Shopify__Product__gid://shopify/Product/8324454187276',
  'Shopify__Product__gid://shopify/Product/8324459561228',
  'Shopify__Product__gid://shopify/Product/8458540318988',
  'Shopify__Product__gid://shopify/Product/8458540777740',
  'Shopify__Product__gid://shopify/Product/8744107245836',
  'Shopify__Product__gid://shopify/Product/8744108359948',
];

export const isStickerProduct = productId =>
  stickerProductIds.includes(`Shopify__Product__${productId}`);

export const glitterStickerProductIds = [
  'Shopify__Product__gid://shopify/Product/4767573278859',
  'Shopify__Product__gid://shopify/Product/4793056854155',
  'Shopify__Product__gid://shopify/Product/6201266208936',
  'Shopify__Product__gid://shopify/Product/6725968461992',
  'Shopify__Product__gid://shopify/Product/7330472034520',
  'Shopify__Product__gid://shopify/Product/7529056633048',
  'Shopify__Product__gid://shopify/Product/7545028804824',
  'Shopify__Product__gid://shopify/Product/7591565426904',
  'Shopify__Product__gid://shopify/Product/7646962778328',
  'Shopify__Product__gid://shopify/Product/8038594674956',
  'Shopify__Product__gid://shopify/Product/8324459561228',
  'Shopify__Product__gid://shopify/Product/8458540777740',
  'Shopify__Product__gid://shopify/Product/8744108359948',
];

export const isGlitterStickerProduct = productId =>
  glitterStickerProductIds.includes(`Shopify__Product__${productId}`);

/**
 * Stickerzentrale project IDs of pro shops. Pro shops will
 * display different text / modify the UI.
 */
const proShopIds = [287, 288, 426, 450, 513, 548, 559, 565, 598, 692, 810, 910, 1034];

export const landwirtschaftShopId = 598;

export const isProShop = shopId => proShopIds.indexOf(shopId) > -1;

/**
 * These are (merch-)products that don't require the customer to upload
 * the consent form - usually PRO products.
 */
export const merchProductConsentWhitelist = [
  'Shopify__Product__gid://shopify/Product/6725936775336',
  'Shopify__Product__gid://shopify/Product/6725975572648',
  'Shopify__Product__gid://shopify/Product/7641451135192',
];

export const productRequiresConsent = productId =>
  !(
    merchProductConsentWhitelist.includes(`Shopify__Product__${productId}`) ||
    isStickerProduct(productId)
  );

/**
 * Products that don't require the user to select a sticker number.
 */
export const merchProductStickerIdWhitelist = [
  'Shopify__Product__gid://shopify/Product/7641451135192',
];

export const productRequiresStickerId = productId =>
  !merchProductStickerIdWhitelist.includes(`Shopify__Product__${productId}`);;

export const deliverFreeTotal = 50;

export const deliverFeeGer = 4.9;

export const deliverFeeStickerGer = 3.5;
