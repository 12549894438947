import React, { useState, useEffect } from 'react';
import Client from 'shopify-buy';
import { landwirtschaftShopId } from '../config';

import Context from '../context/StoreContext';
import fetchClubs from './fetchClubs';

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.SHOP_NAME}.myshopify.com`,
});

const ContextProvider = ({ children }) => {
  let initialStoreState = {
    clubId: undefined,
    clubName: '',
    clubs: [],
    client,
    adding: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    theme: 'normal',
  };

  const [store, updateStore] = useState(initialStoreState);

  /** get selected club from localStorage */
  useEffect(() => {
    const clubId = localStorage.getItem('club_id');
    const clubName = localStorage.getItem('club_name');
    const theme = clubId == landwirtschaftShopId ? 'edeka' : 'normal';

    updateStore(prevState => ({
      ...prevState,
      clubId,
      clubName,
      theme,
    }));
  }, [store.clubs]);

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined';
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null;

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id);
        }

        updateStore(prevState => {
          return { ...prevState, checkout };
        });
      };

      const createNewCheckout = () => store.client.checkout.create();
      const fetchCheckout = id => store.client.checkout.fetch(id);

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID);

          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null);
        }
      }

      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();
  }, [store.client.checkout]);

  /** fetch clubs list on load */
  useEffect(() => {
    fetchClubs()
      .then(res => res.json())
      .then(clubs => {
        updateStore(prevState => ({ ...prevState, clubs }));
      });
  }, []);

  return (
    <Context.Provider
      value={{
        store,
        setClub: clubId => {
          const club = store.clubs.find(({ id }) => id === clubId);
          const theme = clubId === 548 ? 'edeka' : 'normal';

          updateStore(prevState => ({
            ...prevState,
            clubId,
            clubName: club.title,
            theme,
          }));
          localStorage.setItem('club_id', clubId);
          localStorage.setItem('club_name', club.title);
        },
        resetClub: () => {
          updateStore(prevState => ({
            ...prevState,
            clubId: undefined,
            clubName: '',
            theme: 'normal',
          }));
          localStorage.removeItem('club_id');
          localStorage.removeItem('club_name');
        },
        addVariantToCart: async (variantId, quantity, customAttributes) => {
          if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
          }

          updateStore(prevState => {
            return { ...prevState, adding: true };
          });

          const { checkout, client } = store;

          const checkoutId = checkout.id;
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10), customAttributes },
          ];

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout, adding: false };
              });
            });
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res };
              });
            });
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ];

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res };
              });
            });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default ContextProvider;
