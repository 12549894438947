import { useContext } from 'react';

import StoreContext from '../context/StoreContext';

const useCart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return {
    ...checkout,
  };
};

export default useCart;
