import React from 'react';
import PropTypes from 'prop-types';
import { GlobalStyle } from '../utils/styles';

const Blank = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
};

Blank.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Blank;
