import React, { useContext } from 'react';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';

import logo from '../../images/logo.svg';
import edekaLogo from '../../images/edeka-logo.png';
import basket from '../../images/basket.svg';
import StoreContext from '../../context/StoreContext';
import useCart from '../../hooks/useCart';
import formatCurrency from '../../utils/formatCurrency';
import {
  CartCounter,
  HeaderContainer,
  HeaderWrapper,
  LogoImage,
  MenuLink,
  BasketImage,
  EdekaImage,
  GoBackButton,
  CartLink,
} from './styles';
import './style.css';

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const total = reduce(items, (acc, item) => acc + item.quantity, 0);
  return [total !== 0, total];
};

const Navigation = () => {
  const [hasItems, quantity] = useQuantity();
  const { totalPrice } = useCart();
  const {
    store: { theme },
  } = useContext(StoreContext);

  const notHome =
    typeof window !== 'undefined' && window.location.pathname.length > 1;

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <div style={{ display: 'flex' }}>
          <MenuLink to="/">
            {theme === 'edeka' ? (
              <EdekaImage src={edekaLogo} alt="Stickerstars + Edeka logo" />
            ) : (
              <LogoImage src={logo} alt="Stickerstars shop logo" />
            )}
          </MenuLink>
          {notHome && (
            <GoBackButton
              to="/"
              style={{ color: theme === 'edeka' ? '#1f3d7e' : '#E0004D' }}
            >
              <svg
                height={20}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                />
              </svg>
              <span style={{ paddingLeft: '8px' }}>Zurück</span>
            </GoBackButton>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {hasItems && (
            <div className="nav-total">{formatCurrency(totalPrice.amount)}</div>
          )}
          <CartLink to="/cart" data-testid="qa-cart-link">
            {hasItems && <CartCounter>{quantity}</CartCounter>}
            <BasketImage src={basket} alt="Warenkorb Icon" />
          </CartLink>
        </div>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

Navigation.propTypes = {
  siteTitle: PropTypes.string,
};

Navigation.defaultProps = {
  siteTitle: ``,
};

export default Navigation;
