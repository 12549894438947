import React, { useContext, useRef, useEffect } from 'react';

import './styles.css';
import StoreContext from '../../context/StoreContext';
import InputSelect from '../InputSelect';

import logo from '../../images/logo.svg';
import { LogoImageStartPage } from '../Navigation/styles';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const maintenanceModeActive = process.env.MAINTENANCE;

const ClubSelect = () => {
  const {
    store: { clubs },
    setClub,
  } = useContext(StoreContext);

  const clubInputRef = useRef(null);

  useEffect(() => {
    if (clubInputRef.current) {
      clubInputRef.current.focus();
    }
  });

  const handleClubSelect = clubId => {
    setClub(clubId);
  };

  return (
    <div className="flex-root">
      <Link to="/">
        <LogoImageStartPage src={logo} alt="Stickerstars shop logo" />
      </Link>
      <div data-testid="qa-club-select" className="club-input">
        {maintenanceModeActive ? (
          <>
            <h1>Hallo Stickerstars,</h1>
            <p>
              <b>Gute Nachrichten</b> - sehr bald findet Ihr hier einen neuen,
              besseren Shop! Um einen entspannten Übergang zu ermöglichen, haben
              wir den alten deaktiviert.
              <br />
              <br />
              Schaut bald wieder vorbei.
            </p>
          </>
        ) : (
          <>
            <p>
              Du möchtest Sticker nachbestellen oder tolle Produkte mit deinem
              Sticker?
            </p>
            <h1>Hier auswählen und bestellen!</h1>
            <InputSelect
              ref={clubInputRef}
              data-testid="qa-club-filter-input"
              options={clubs.map(({ id, title }) => [id, title])}
              onChange={({ target: { value } }) => handleClubSelect(value)}
              minLength={3}
              placeholder="Projekt / Verein / Feuerwehr suchen..."
              infotext
            />
            <p className="small-text">
              Du hast noch Fragen? Dann melde dich bei uns unter:{' '}
              <a className="inner-link" href="mailto:shop@stickerstars.de">
                shop@stickerstars.de
              </a>
              .
            </p>
          </>
        )}
      </div>
      <StaticImage
        className="clup-input-start-image"
        src="../../images/stickershop_xl.png"
        alt="Bild mit Laptop mit offenem Shop"
      />
    </div>
  );
};

export default ClubSelect;
