import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
} from 'react';

import './style.css';

const InputSelect = (
  {
    options,
    onChange,
    minLength = 0,
    placeholder = '',
    type = 'text',
    infotext = false,
  },
  ref
) => {
  const [val, setVal] = useState('');
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setVal('');
    },
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const filteredOptions = options.filter(
    ([_, name]) => name.toLowerCase().indexOf(val.toLowerCase()) > -1
  );

  const handleSelect = value => {
    onChange({
      target: {
        value,
      },
    });
  };

  const handleKeyPress = e => {
    const { key } = e;

    if (key === 'Enter') {
      const option = options.find(([_, k]) => k === val);

      const target =
        option || (filteredOptions.length === 1 && filteredOptions[0]);

      if (target) {
        onChange({
          target: {
            value: option[0],
          },
        });
      }
    }
  };

  return (
    <div className="select-input-wrapper">
      <input
        type={type}
        data-testid="qa-select-input"
        value={val}
        onKeyPress={handleKeyPress}
        onChange={({ target: { value } }) => setVal(value)}
        placeholder={placeholder}
        ref={inputRef}
      />
      {val.length > minLength && filteredOptions.length > 0 && (
        <div className="results">
          {filteredOptions.splice(0, 5).map(([v, k]) => (
            <div
              data-testid={`qa-result-${v}`}
              key={v}
              className="item"
              onClick={() => handleSelect(v)}
            >
              {k}
            </div>
          ))}
        </div>
      )}
      {infotext && (
        <p className="select-input-advise">
          Das ist eine Schlagwortsuche, das heißt du musst nur einen Teil des
          Projektnamens eingeben, um anschließend auf den vorgegebenen Vorschlag
          zu klicken.
          <br />
          Beispiel: Du suchst nach Stickern für das Projekt{' '}
          <b>SV Blau-Weiß Petershagen,</b> dann tippe nur <b>"Petershagen"</b>{' '}
          ein und klicke auf den Vorschlag im Feld unter der Suchleiste.
        </p>
      )}
    </div>
  );
};

export default forwardRef(InputSelect);
