import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent';

import ContextProvider from '~/provider/ContextProvider';
import PageLayout from './PageLayout';
import Blank from './Blank';
import Empty from './Empty';

import './styles.css';

const Layout = ({ children, pageContext }) => {
  useEffect(() => {
    let cc = window.initCookieConsent();

    cc.run({
      revision: 1,
      gui_options: {
        consent_modal: {
          layout: 'cloud',
        }
      },
      delay: 250,
      autorun: true,
      page_scripts: true,
      hide_from_bots: true,
      current_lang: 'de',
      force_consent: true,
      cookie_name: 'sstcookieconsentv2',
      cookie_domain: '.stickerstars.de',
      languages: {
        de: {
          consent_modal: {
            title: '🍪 Cookies und Datenschutz',
            description:
              'Um dir das bestmögliche Surferlebnis zu ermöglichen, würden wir gerne ein paar Cookies speichern! Nähere Informationen dazu findest Du in unserer <a target="__blank" aria-label="Link zur Datenschutzseite" class="cc-link" href="https://stickerstars.de/datenschutz">Datenschutzerklärung</a>. Bitte klicke auf „Ich stimme zu“, um die Cookies zu akzeptieren und unsere Website besuchen zu können!',
            primary_btn: {
              text: 'Ich stimme zu',
              role: 'accept_all',
            },
            secondary_btn: {
              text: 'Einstellungen',
              role: 'settings'
            }
          },
          settings_modal: {
            title: 'Cookie-Einstellungen',
            save_settings_btn: 'Einstellungen speichern',
            accept_all_btn: 'Alle akzeptieren',
            reject_all_btn: 'Alle ablehnen',
            close_btn_label: 'schließeen',
            blocks: [
              {
                description: 'Wir verwende Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um dein Online-Erlebnis zu verbessern. Du kannst für jede Kategorie wählen, ob Du diese Cookies akzeptierst -- oder nicht.'
              },
              {
                title: 'Notwendige Cookies',
                description:
                  'Notwendige Cookies (und ähnliche Technologien) sind für einen reibungslosen Betrieb der Webseite erforderlich und können nicht deaktiviert werden.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
              }, {
                title: 'Cookies für Analysen und Marketing',
                description: 'Diese Cookies sammeln Informationen darüber, wie du die Website nutzt, welche Seiten du besuchst und welche Links du angeklickt hast. Alle Daten sind anonymisiert und können nicht verwendet werden, um dich zu identifizieren',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false
                }
              },
              {
                title: 'Weitere Informationen',
                description: 'Für weitere Informationen zu unserer Cookie-Policy und unseren Datenschutzrichtlinien besuche bitte unsere <a class="cc-link" target="_BLANK" href="https://www.stickerstars.de/datenschutz">Datenschutz</a>-Seite.',
              }
            ],
          },
        },
      },
    });
  }, []);

  /** a template _without_ anything wrapping the page */
  if (pageContext.layout === 'blank') {
    return <Blank>{children}</Blank>;
  }

  /** a template _with_ context but _without_ styles / navigation */
  if (pageContext.layout === 'empty') {
    return <Empty>{children}</Empty>;
  }

  /** regular page template */
  return (
    <ContextProvider>
      <PageLayout>{children}</PageLayout>
    </ContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
